<template>
  <div class="home">
    Hello world!!
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.home {
  text-align: left;
}
</style>
